import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Landing from "./page/Landing";
import Navbar from './components/Navbar';
import Footer from './page/section/footer';
import Nopage from './page/404';
import NewsFeed from './page/ArticlePage';
import AboutUs from './page/About';
import Contact from './page/Contact';
import Videos from './page/video';
import EntertainmentFull from './page/newsSections/entertainment';
import Latest from './page/newsSections/latest';
import Sportsnews from './page/newsSections/sportsnews';
import Businessnew from './page/newsSections/businessnews';
import Maintenance from './page/maintenance';

function App() {
  return (
    <div className='bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-white py-1'>
      {/* <Navbar /> */}
      <BrowserRouter>
        <Routes>
          { <Route index element = {<Maintenance />} /> }
          {/* <Route index element = {<Landing />} /> */}
          {/* <Route path= "/video" element = {<Videos />} />
          <Route path= "/article" element = {<NewsFeed />} />
          <Route path ="/about us" element = {< AboutUs />} />
          <Route path ="/contact us" element = {< Contact />} />
          <Route path = "/entertainment" element = {<EntertainmentFull/>} />
          <Route path ="/sports" element = {<Sportsnews/>} />
          <Route path ="/business" element = {<Businessnew />} />
          <Route path ="/latest" element = {<Latest/>} /> */}
          <Route path= "*" element = {<Nopage /> } />
        </Routes>
      </BrowserRouter>
      {/* < Footer /> */}
    </div>


  );
}

export default App;
